.custom-card {
   background: #ffffff;
   border-radius: 4px;
   box-shadow: 0px 2px 4px rgba(81, 89, 120, 0.16);
   padding: 30px 25px;
   margin-bottom: 25px;
   position: relative;
}

.custom-card.full-height {
   height: 100%;
}

.custom-card.gradient-border::before {
   content: '';
   background: linear-gradient(270deg, #00d2ff -8.34%, #7e51ff 50.93%, #fa4a84 106.85%);
   height: 2px;
   width: 100%;
   position: absolute;
   top: 0;
   left: 0;
}

.custom-card .card-title {
   font-size: 16px;
   font-weight: 600;
   color: #04093f;
}

.custom-card .card-value {
   font-size: 28px;
   /* font-weight: 500; */
   line-height: 35px;
   color: #04093f;
}

.custom-card .card-description {
   font-size: 14px;
   /* font-weight: 500; */
   line-height: 18px;
   color: #515978;
   margin-bottom: 5px;
   text-transform: uppercase;
   white-space: nowrap;
}

.custom-card .chart-filter {
   height: 35px;
   border: 1px solid #edf0f3;
   border-radius: 4px;
   background: #ffffff;
   font-size: 12px;
   font-weight: 600;
}

.custom-card .chart-filter span {
   width: 85px;
   height: 100%;
   border-right: 1px solid #edf0f3;
   position: relative;
   color: #515978;
   transition: all ease-in-out 0.3s;
   cursor: pointer;
}

.custom-card .chart-filter span::after {
   position: absolute;
   content: '';
   border: 1px solid #fa4a84;
   top: -1px;
   right: -1px;
   bottom: -1px;
   left: -1px;
   opacity: 0;
   border-radius: 4px;
   transition: all ease-in-out 0.3s;
}

.custom-card .chart-filter span.active {
   color: #fa4a84;
}

.custom-card .chart-filter span.active::after {
   opacity: 1;
}

.custom-card .chart-filter span:last-child {
   border-right: 0;
}

.custom-card .chart-container {
   margin-top: 15px;
}
